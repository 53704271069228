* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
  font-size: 2vh;
  line-height: 3.7vh;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

.home {
  position: relative;
  padding-top: 32vh;
  font-size: 2.7vh;
  line-height: 6vh;
  text-align: center;

  h1 {
    line-height: 20vh;
    margin-top: -10vh;
    margin-bottom: -6vh;
    font-size: 16vh;
    -webkit-text-stroke: 3px whitesmoke;
    color: #07070f;
  }

}
.about {
  position: relative;
  margin-top: 0;
  padding-top: 16vh;
  font-size: 2.7vh;
  line-height: 5.5vh;

  h1 {
    font-size: 14vh;
    text-align: center;
    -webkit-text-stroke: 3px whitesmoke;
    color: #07070f;
  }

  p {
    text-align: left;
    text-indent: 2.7vh;
  }

}

/*.portfolio {
  position: relative;
  margin-top: 0;
  padding-top: 14vh;
  font-size: 2.7vh;
  line-height: 5.8vh;

  h1 {
    font-size: 14vh;
    text-align: center;
    -webkit-text-stroke: 5px whitesmoke;
    color: transparent;
  }

  p {
    padding-top: 6.5vh;
    text-align: center;
    color: whitesmoke;
    line-height: 3.8vh;
  }
}*/

.contact {
  z-index: 100;
  position: relative;
  margin-top: 0;
  /*padding-top: 14vh;*/
  top: -86vh;
  font-size: 2.7vh;
  line-height: 5.8vh;
  /*overflow: hidden;*/
  /*left: 100%;*/
  h1 {
    font-size: 14vh;
    text-align: center;
    -webkit-text-stroke: 5px whitesmoke;
    color: transparent;
  }

  p {
    padding-top: 6.5vh;
    text-align: center;
    color: whitesmoke;
    line-height: 3.8vh;
  }

  .contact-wrapper {
    display: flex;
    justify-content: center;
    /*align-items: center;*/
    /*height: 100%;*/
    width: 100%;
    padding-top: 100px;
  }

  .contact-text {
    outline: 4px solid whitesmoke;
    padding: 10px 20px 10px 20px;
    position: absolute;
    margin-top: 10px;
    z-index: 1;
    text-align: center;
    background-color: #111;
    min-width: 40%;
    hr {
      color: whitesmoke;
    }
  }
}



.about-text {
  outline: 4px solid #d5d5d5;
  padding: 3vh 1vw 3vh 1.5vw;
  width: 35vw;
  position: absolute;
  left: 12vw;
  margin-top: 10px;
}

.tag-cloud {
  position: absolute;
  right: 8vw;
  top: 30vh;
}

.home-stars {
  position: absolute;
  top: 0;
  left: -8%;

  height: 100vh;
  width: 110%;
}
.home-big {
  position: absolute;
  top: 0;
  left: -8%;
  opacity: 30%;
  rotate: 180deg;

  height: 100vh;
  width: 110%;
}

.about-stars {
  position: absolute;
  top: 0;
  left: -8%;

  height: 100vh;
  width: 110%;
  mask-image: linear-gradient(to top,  rgba(255,255,255,0) 0,rgba(255,255,255,1) 80%);
}

.link-button {
  z-index: 1;
  display: inline-block;
  padding: 20px;
  align-items: center;
  justify-content: center;
  position: relative;
  line-height: 70px;
  color: #07070f;

  a:link, a:visited, a:hover, a:active {
    text-decoration: none;
    color: whitesmoke;
  }
}

.link-button-child {
  height: 75px;
  width: 150px;

  outline: 4px solid whitesmoke;
  display: inline-block;

}

html,
body,
#root,
#app,
.App{
}

html{
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}
.bottom-links {
  position: fixed;
  bottom: 2%;
}

.icon-box {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 50px;
  width: 80px;
}

.icons {
  height: 35px;
  width: 35px;

}

section {
  height: 100vh;
  scroll-snap-align: center;
  padding-top: 10px;
}

.testName {
  position: fixed;
  /*top: calc(50% - 160px);*/
  top: calc(50% - 140px);
}



.box {
  height: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

}

.App-logo {
  height: 50px;
  width: 50px;

}

body {
  background-color: black;
  position: static;
}

nav {
  background-color: black;
  width: 100%;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
}

nav .nav-container {
  padding: 10px;
  display: flex;
  justify-content: space-between;
}



main {
  margin: 0 0 0 100px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  color: aliceblue;
  background-image:linear-gradient(#060614 25%, 75%, #2f8bfa);

}

.cloud-parent {
  position: relative;
  /*top: 202vh;*/
  min-height: 100vh;
  /*min-width: 100%;*/
  /*bottom: -5vh;*/
}

.cloud-child {
  z-index: 0;
  position: relative;
  top: 0;
  height: 100vh;
  overflow: hidden;
  /*vertical-align: bottom;*/
  /*bottom: -23vh;*/
  left: -4vw;
  width: calc(100% + 100px);
}

.fly-cloud {
  position: absolute;
  top: 60vh;
  z-index: 0;
  max-width: inherit;
}

aside {
  position: fixed;
  left: 0;
  /*top: 50px;*/

  top: 0;
  bottom: 0;
  padding: 10px;
  background-color: #111;
  width: 100px;
  z-index: 1;
}

@media screen and (max-width: 775px) {
  main {
    margin-left: 100px;
  }
}

::-webkit-scrollbar {
  height: 0;
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: black;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #444;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #333;
}
